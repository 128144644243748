import { Container,Navbar, Row, Col } from 'react-bootstrap';
export default function Loading() {

    return (
        <Container className='loading-contain'>
            <Row className='px-3 mt-5 mb-5'>
                <Col className='text-center text-white'>
                    Loading...
                </Col>
            </Row>
        </Container>           
    )
}