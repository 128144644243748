import React, { Suspense, useState, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import Loading from '../components/Loading';
import Step1 from '../components/Step1';
import Step2 from '../components/Step2';
import Step3 from '../components/Step3';
import emitter from '../utils/emitter';
import { useWaitForTransaction } from 'wagmi';

export default function Home() {

    const [load, setLoad] = useState(false);
    const [step, setStep] = useState(1);
    const [txHash, setTxHash] = useState('')
    const { data } = useWaitForTransaction({
        hash: txHash,
        enabled: !!txHash,
    });
    var myTimeout = "";
    useEffect(() => {
        setLoad(true);
        emitter.addListener("updateStep", (data) => {
            setStep(data.value);
        })
        if (step === 2) {
            const isMainnet = localStorage.getItem("from_img_name") === "Mainnet"
            let sourceChainId = isMainnet ? 101 : 110
            myTimeout = setTimeout(function () {
                // const { messages } = getMessagesBySrcTxHash(
                //     sourceChainId,
                //     tx_hash,
                // );

                setStep(3);
            }, 90_000);
        }
        emitter.addListener("closeStep2", (data) => {
            setStep(data.value);
            clearTimeout(myTimeout);
        })
        emitter.addListener("closeStep3", (data) => {
            setStep(data.value);
            return;
        })


    }, [step]);

    return (
        !load ? (
            <Loading />
        ) : (
            <Container>
                <Row>
                    <Col className='py-4 px-3'>
                        {
                            step === 1 && (
                                <Step1
                                    setTxHash={setTxHash}
                                />
                            )
                        }
                        {
                            step === 2 && (
                                <Step2
                                    txHash={txHash}
                                />
                            )
                        }
                        {
                            step === 3 && (
                                <Step3
                                    txHash={txHash}
                                />
                            )
                        }

                    </Col>
                </Row>
            </Container>
        )

    )
}

