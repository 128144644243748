import { Container, Row, Col } from 'react-bootstrap';
import { Twitter, Discord, Telegram } from 'react-bootstrap-icons';
import twitter from "../../assets/img/twitter-icon.svg";
import second from "../../assets/img/s1-icon.svg";
import book from "../../assets/img/book-icon.svg";
import discord from "../../assets/img/discord-icon.svg";
import telegram from "../../assets/img/telegram-icon.svg";

export default function Footer() {
    return (
        <footer className='footer-bg py-4'>
            <Container>
                <Row>
                    <Col>
                        <div className='footer-social-div d-flex flex-center'>
                            <div className='px-3 social-icon'>
                                <a href="https://twitter.com/empyrealSDK" rel="noreferrer" target="_blank" className='text-white'><img src={twitter} width="26px" /></a>
                            </div>
                            <div className='px-3 social-icon'>
                                <a href="https://medium.com/@empyrealsdk" rel="noreferrer" target="_blank" className='text-white'><img src={second} width="26px" /></a>
                            </div>
                            <div className='px-3 social-icon'>
                                <a href="https://docs.empyreal.app" rel="noreferrer" target="_blank" className='text-white'><img src={book} width="26px" /></a>
                            </div>
                            <div className='px-3 social-icon'>
                                <a href="https://discord.gg/empyrealsdk" rel="noreferrer" target="_blank" className='text-white'><img src={discord} width="26px" /></a>
                            </div>
                            <div className='px-3 social-icon'>
                                <a href="https://t.me/empyrealportal" className='text-white'><img src={telegram} width="26px" /></a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
