import React, { Suspense } from "react";
import "./assets/css/style.css";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { WagmiConfig, createClient } from 'wagmi'
import { arbitrum, mainnet } from 'wagmi/chains'
import { publicProvider } from '@wagmi/core/providers/public'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";

import { configureChains } from "@wagmi/core";
import { InjectedConnector } from 'wagmi/connectors/injected'

const { chains, provider } = configureChains(
  [arbitrum, mainnet],
  [
    publicProvider(),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: 'https://rpc.ankr.com/arbitrum',
      }),
    }),],
)

const client = createClient({
  autoConnect: true,
  connectors: [new InjectedConnector({ chains })],
  provider,
});


function App() {
  return (
    <WagmiConfig client={client}>
      <BrowserRouter>
        <ToastContainer position='top-center' limit={1} />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </WagmiConfig>
  );
}

export default App;
