import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { ethers } from "ethers";
import { useAccount, useBalance, useNetwork, useSwitchNetwork, usePrepareContractWrite, useContractWrite, useContractRead } from "wagmi";
import from_url from "../assets/img/from_icon.svg";
import to_url from "../assets/img/to_icon.svg";
import h_arrow from "../assets/img/h-arrow.svg";
import v_arrow from "../assets/img/v-arrow.svg";
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import emitter from '../utils/emitter';
import EmpABI from "../EmpABI.json";


const ARB_ADDRESS = '0x772598E9e62155D7fDFe65FdF01EB5a53a8465BE';
const MAINNET_ADDRESS = '0x39D5313C3750140E5042887413bA8AA6145a9bd2';

export default function Step1(props) {
    const { setTxHash } = props;
    const [btnName, setBtnName] = useState("Enter an Amount");
    const [amount, setAmount] = useState("");
    const [fromText, setFromText] = useState("Mainnet");
    const [toText, setToText] = useState("Arbitrum");
    const [fromUrl, setFromUrl] = useState(from_url);
    const [toUrl, setToUrl] = useState(to_url);
    const [flag, setFlag] = useState(false);


    const { address, isConnected } = useAccount();
    const { chain } = useNetwork();
    const { data: ethBalance } = useBalance({
        address,
    })

    useEffect(() => {
        if (chain?.id == 1) {
            setFromText("Mainnet");
            setToText("Arbitrum");
            setFromUrl(from_url);
            setToUrl(to_url);
            localStorage.setItem("from_img_name", "Mainnet");
        } else if (chain?.id == 42161) {
            setFromText("Arbitrum");
            setToText("Mainnet");
            setFromUrl(to_url);
            setToUrl(from_url);
            localStorage.setItem("from_img_name", "Arbitrum");
        }
    }, [chain])
    const tokenAddress = chain?.id == 1 ? MAINNET_ADDRESS : ARB_ADDRESS;

    const { switchNetwork } = useSwitchNetwork()
    const { data, isError, isLoading } = useBalance({
        address,
        token: chain?.id == 1 ? MAINNET_ADDRESS : ARB_ADDRESS,
        enabled: isConnected && !!address && !!chain,
    });
    function setMax() {
        setAmount(data?.formatted);
    }

    if (!localStorage.getItem("from_img_name")) {
        localStorage.setItem("from_img_name", "Mainnet");
    }

    const fromToHandler = () => {
        console.log('flag', switchNetwork)
        if (flag) {
            setFromText("Mainnet");
            setToText("Arbitrum");
            setFromUrl(from_url);
            switchNetwork?.(1);
            setToUrl(to_url);
            localStorage.setItem("from_img_name", "Mainnet");
            setFlag(false);
        } else {
            setFromText("Arbitrum");
            setToText("Mainnet");
            switchNetwork?.(42161);
            setFromUrl(to_url);
            setToUrl(from_url);
            localStorage.setItem("from_img_name", "Arbitrum");
            setFlag(true);
        }
    }

    const amountHandler = (e) => {
        if (e.target.value == "") {
            setAmount("");
            return;
        }
        if (e.target.value.match("^[+-]?(([1-9][0-9]*)?[0-9](\.[0-9]*)?|\.[0-9]+)$")) {
            setAmount(e.target.value);
        }
    }
    useEffect(() => {
        if (amount > 0) {
            setBtnName("Confirm & Send")
        } else {
            setBtnName("Enter an amount")
        }

    }, [amount]);

    const destinationChainId = chain?.id == 1 ? 110 : 101;
    const coder = ethers.utils.defaultAbiCoder;
    let defaultAdapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, 200000])
    let addressBytes32
    if (address) {
        addressBytes32 = coder.encode(["address"], [address]);
    }

    const { data: nativeFee, error } = useContractRead({
        address: tokenAddress,
        abi: EmpABI,
        functionName: "estimateSendFee",
        args: [
            destinationChainId,
            addressBytes32,
            ethers.utils.parseEther(amount || "0"),
            false,
            defaultAdapterParams,
        ],
        enabled: !!amount & isConnected & !!addressBytes32,
    });
    const { config, error: writeError } = usePrepareContractWrite({
        address: tokenAddress,
        chainId: chain?.id,
        abi: EmpABI,
        functionName: 'sendFrom',
        args: [
            address,
            destinationChainId,
            addressBytes32,
            ethers.utils.parseEther(amount || "0"),
            [
                address,
                address,
                defaultAdapterParams,
            ],
            {
                value: nativeFee?.nativeFee,
            },

        ],
        enabled: !!nativeFee?.nativeFee & Number(amount || '0') > 0 & isConnected & !!chain?.id,
    })
    const { data: writeTx, write } = useContractWrite({
        ...config,
        onSuccess: () => {
            emitter.emit('updateStep', { value: 2 });
        }
    })
    const nextHandler = async () => {
        if (!isConnected) {
            toast.error("Please connect your wallet");
            return;
        }

        if (amount == "") {
            toast.error("Enter an amount!");
            return;
        }

        if (!write) {
            if (ethers.utils.parseEther(amount || "0").gt(data?.value || "0")) {
                toast.error("Insufficient EMP Balance");
                return;
            }
            if (ethBalance?.value.lt(nativeFee?.nativeFee)) {
                console.log(writeError)
                toast.error(`Insufficient ETH Balance.  Must have at least ${ethers.utils.formatEther(nativeFee?.nativeFee).slice(0, 6)} ETH for gas`);
                return;
            }

            toast.error(writeError?.error?.data?.message)
            return;
        }

        await write();
    }

    useEffect(() => {
        setTxHash(writeTx?.hash);
    }, [writeTx]);

    return (
        <div className='step1-parent-div'>
            <Row>
                <Col sm={5}>
                    <div className='box-shadow1 bg-light-gray d-flex align-items-center radius-5 px-4 py-3 my-2'>
                        <div>
                            <img src={fromUrl} width="45px" />
                        </div>
                        <div className='px-2'>
                            <div className='color-blue-gray font-12 bold-700'>From</div>
                            <div className='color-dark-blue font-18 bold-700'>{fromText}</div>
                        </div>
                    </div>
                </Col>
                <Col sm={2} className='d-flex align-items-center flex-center my-2'>
                    <div onClick={fromToHandler} className='cursur-pointer'>
                        <img src={h_arrow} width="45px" className='desktop-show1' />
                        <img src={v_arrow} width="40px" className='mobile-show1' />
                    </div>
                </Col>
                <Col sm={5}>
                    <div className='box-shadow1 bg-light-gray d-flex align-items-center radius-5 px-4 py-3 my-2'>
                        <div>
                            <img src={toUrl} width="45px" />
                        </div>
                        <div className='px-2'>
                            <div className='color-blue-gray font-12 bold-700'>To</div>
                            <div className='color-dark-blue font-18 bold-700'>{toText}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <div className='box-shadow1 bg-light-gray d-flex align-items-center space-between radius-5 px-4 py-3 my-2'>
                        <div>
                            <Button
                                onClick={setMax}
                                className='btn_max font-16 bold-400 color-light-gray'>Max</Button>
                        </div>
                        <div className='input_div px-2'>
                            <Form.Control type="text" placeholder="0.00000000" value={amount} onChange={(e) => amountHandler(e)} className='input_amount' />
                        </div>
                        <div className='balance_div'>
                            <div className='color-blue-light font-12 bold-700'>Balance</div>
                            <div className='color-blue-gray font-12 bold-700'>{data?.formatted.slice(0, 10) || 0} EMP</div>
                        </div>
                    </div>
                </Col>

            </Row>
            <Row className='mt-2'>
                <Col>
                    <div className='d-flex space-between px-2'>
                        {/* <div className='color-blue-gray font-14 bold-600'>Gas on destination</div>
                        <div className='color-blue-gray font-14 bold-600'>0.000000000 ARB</div> */}
                    </div>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <div className=''>
                        <button
                            className='btn_enter_amount w-100 text-white'
                            onClick={nextHandler}
                        >{btnName}</button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
