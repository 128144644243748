import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import exit_url from "../assets/img/exit.svg";
import mainnet_url from "../assets/img/mainnet.svg";
import arbitrum_url from "../assets/img/arbitrum.svg";
import h_complete_url from "../assets/img/complete_check.svg";
import emitter from '../utils/emitter';
import { useEffect, useState } from 'react';

export default function Step3(props) {
    const { txHash } = props;
    const [fromText, setFromText] = useState("MAINNET");
    const [toText, setToText] = useState("ARBITRUM");
    const [fromUrl, setFromUrl] = useState(mainnet_url);
    const [toUrl, setToUrl] = useState(arbitrum_url);
    const [flag, setFlag] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("from_img_name") === "Mainnet") {
            setFromText("MAINNET");
            setToText("ARBITRUM");
            setFromUrl(mainnet_url);
            setToUrl(arbitrum_url);
            setFlag(false);
        } else {
            setFromText("ARBITRUM");
            setToText("MAINNET");
            setFromUrl(arbitrum_url);
            setToUrl(mainnet_url);
            setFlag(true);
        }
    }, []);
    const closeHander = () => {
        localStorage.setItem("from_img_name", "Mainnet");
        emitter.emit("closeStep3", { value: 1 });
    }
    return (
        <div className='step2-parent-div'>
            <Row className='py-4'>
                <Col>
                    <div className='text-center positoin-relative'>
                        <div className='font-18 bold-700 color-black'>Completed</div>
                        <div className='exit_div'>
                            <Button className='btn-exit' onClick={closeHander}><img src={exit_url} width="15px" /></Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='bg-dark-blue px-3 py-2 w-100 m-auto'>
                {/* <Col sm={6}>
                    <div className='d-flex space-around'>
                        <div className='color-light-gray font-14 bold-600'>Timestamp</div>
                        <div className='color-light-gray font-14 bold-600'>2023-11-15 00:00:00</div>
                    </div>
                </Col>
                <Col sm={6}>
                    <div className='d-flex space-around'>
                        <div className='color-light-gray font-14 bold-600'>Value</div>
                        <div className='color-light-gray font-14 bold-600'>0.000000000 ARB</div>
                    </div>
                </Col> */}
            </Row>
            <Row className='padding-50-50'>
                <Col sm={4}>
                    <div className='bg-light-gray  text-center px-4 py-3 my-2'>
                        <div className='px-2'>
                            <div className='color-dark-blue font-16 bold-700'>{fromText}</div>
                        </div>
                        <div className='my-3'>
                            <img src={fromUrl} width="80px" />
                        </div>
                        <div className='px-2'>
                            <div className='color-blue-gray font-12 bold-600 text_underline'>
                                <a href={`https://layerzeroscan.com/tx/${txHash}`} target="_blank" className='color-blue-gray'>
                                    View on Explorer
                                </a>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={4} className='d-flex align-items-center flex-center my-2'>
                    <div >
                        <div className='step2-spin-div'>
                            <div className='d-flex flex-center'>
                                <div className='h-dashline'></div>
                            </div>
                            <div className='m-2'>
                                <img src={h_complete_url} width="44px" />
                            </div>
                            <div className='d-flex flex-center'>
                                <div className='h-dashline h-dashline1'></div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={4}>
                    <div className='bg-light-gray  text-center px-4 py-3 my-2'>
                        <div className='px-2'>
                            <div className='color-dark-blue font-16 bold-700'>{toText}</div>
                        </div>
                        <div className='my-3'>
                            <img src={toUrl} width="80px" />
                        </div>
                        <div className='px-2'>
                            <div className='color-blue-gray font-12 bold-600 text_underline'><a href={`https://layerzeroscan.com/tx/${txHash}`} target="_blank" className='color-blue-gray'>View on Explorer</a></div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
