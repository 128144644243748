import { Container, Navbar, Nav, NavDropdown, Button, Collapse } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useAccount, useNetwork, useConnect, useDisconnect, useSwitchNetwork } from "wagmi";
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useLocation, Link } from 'react-router-dom';
import logo from "../../assets/img/logo.svg";
import icon1 from "../../assets/img/icon1.svg";
import icon2 from "../../assets/img/icon2.svg";
import active_url from "../../assets/img/active.png";
import etherum_icon from "../../assets/img/from_icon.svg";

export default function Header() {
  const [open, setOpen] = useState(false);
  const { data: account } = useAccount()
  const [selectedChain, setSelectedChain] = useState(icon1)
  const [chainMenuActive, setChainMenuActive] = useState("arbitrum")
  const { switchNetwork } = useSwitchNetwork()
  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  const chainMenuHandler = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  const closeMenuhandler = () => {
    if (open) {
      setOpen(false);
    }
  }
  window.addEventListener('click', (e) => {
    if (e.target !== document.querySelector('.chain-menu') && e.target !== document.querySelector('.menu-toggle img') && e.target !== document.querySelector('.mobile-toggle-img')) {
      closeMenuhandler();
    }
  })
  const arbtrumHandler = () => {
    setSelectedChain(icon1);
    setChainMenuActive("arbitrum");
    switchNetwork?.(42161)
  }
  const etherHandler = () => {
    setSelectedChain(etherum_icon);
    setChainMenuActive("ether");
    switchNetwork?.(1);
  }

  useEffect(() => {
    if (chain?.id == 1) {
      setSelectedChain(etherum_icon);
      setChainMenuActive("ether");
    } else if (chain?.id == 42161) {
      setSelectedChain(icon1);
      setChainMenuActive("arbitrum");
    }
  }, [chain])
  return (
    <header >
      <Navbar expand="lg">
        <Container className='py-3'>
          <div className="logo-container w-100-m-50">
            <Navbar.Brand href="https://empyrealsdk.com" target="_blank" rel="noreferrer"><img src={logo} className='logo-width' />
            </Navbar.Brand>
          </div>
          <Nav className='position-relative mx-3'>
            <div className='d-flex align-items-center' >
              <div className='p-2'>
                <img src={selectedChain} width={38} className='desktop-show ' />
              </div>
              <div className='cursur-pointer menu-toggle' onClick={chainMenuHandler}>
                <img src={icon2} className='desktop-show' width={30} />
                <img src={selectedChain} className='mobile-show mobile-toggle-img' width={30} />
              </div>
            </div>
            <Collapse in={open} className=''>
              <div className='chain-menu'>
                <div className='font-12 bold-700 color-dark-blue px-2'>Switch Networks</div>
                <div className='d-flex space-between align-items-center p-2 cursur-pointer chain-menu-item' onClick={arbtrumHandler}>
                  <div className=''>
                    <img src={icon1} width={25} /> <span className='font-14 bold-700 color-dark-blue'>ARBITRUM</span>
                  </div>
                  <div>
                    {
                      chainMenuActive === "arbitrum" &&
                      (<img src={active_url} />)
                    }
                  </div>
                </div>
                <div className='d-flex space-between align-items-center p-2 cursur-pointer chain-menu-item' onClick={etherHandler}>
                  <div className=''>
                    <img src={etherum_icon} width={25} /> <span className='font-14 bold-700 color-dark-blue'>ETHEREUM</span>
                  </div>
                  <div>
                    {
                      chainMenuActive === "ether" &&
                      (<img src={active_url} />)
                    }
                  </div>
                </div>
              </div>
            </Collapse>
          </Nav>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="my-2 " navbarScroll>
              {/* <Link to="/" className="">HOME</Link>
            <Link to="/faq" className=''>FAQS</Link> */}
            </Nav>
            <Nav>
              {!isConnected ? <Button onClick={() => connect()} className='connect_btn px-4'>Connect</Button> : <Button onClick={() => disconnect()} className='connect_btn px-4'>0x..{address.slice(-4)}</Button>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header >
  )
}
